<template>
  <b-overlay
    opacity="1"
    :show="isLoading"
  >
    <b-card>
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="
                d-flex
                align-items-center
                justify-content-start
                mb-1 mb-md-0
              "
          >
            <label>Показывать по</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>записей</label>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Поиск..."
              />

            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refPayout"
        class="position-relative"
        :items="fetchItems"
        responsive
        :fields="Columns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Совпадающих записей не найдено"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(status)="data">
          <b-badge :variant="`light-${ resolvePayoutStatusVarian(data.value.name) }`">
            {{ resolveUserPayoutMotivationStatus(data.value.name) }}
          </b-badge>
        </template>

        <template #cell(updatedAt)="data">
          {{ formatDate(data.value) }}
        </template>

        <template #cell(createdAt)="data">
          {{ formatDate(data.value) }}
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Показано с {{ dataMeta.from }} по {{ dataMeta.to }} из {{ dataMeta.of }} элементов</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </b-overlay>
</template>
<script>
import {
  BRow,
  BCol,
  BTable,
  BCard,
  BFormInput,
  BOverlay,
  BPagination,
  BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { elementList } from '@/application/payoutService'
import { formatDate } from '@/libs/helper'
import { avatarText } from '@core/utils/filter'
import {
  generateRandomVariant,
  verifEmailVariant,
  verifEmailIcon,
  resolvePayoutStatusVarian,
  resolveUserPayoutMotivationStatus,
} from '@/utils/UserFilter'

export default {
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BOverlay,
    BPagination,
    BFormInput,
    BBadge,

    vSelect,
  },

  setup() {
    const {
      fetchItems,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPayout,
      Columns,
      clearSelectTable,
      isLoading,

    } = elementList()

    return {
      refPayout,
      fetchItems,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      clearSelectTable,
      isLoading,
      Columns,
      verifEmailVariant,
      verifEmailIcon,
      resolvePayoutStatusVarian,
      resolveUserPayoutMotivationStatus,
      generateRandomVariant,
      formatDate,
      avatarText,
    }
  },
}
</script>
